import { useCallback } from 'react';
import { useController } from 'react-hook-form';

import type { FormFieldTextProps } from './type';
import type { ChangeEventHandler } from 'react';
import type { FieldPath, FieldValues } from 'react-hook-form';

import { FormFieldWrapper, InputText } from '@/components/ui';
import { extractWrapperProps } from '@/components/ui/form/FormFieldWrapper/util';

export const FormFieldText = <T extends FieldValues, U extends FieldPath<T>>({
  name,
  control,
  shouldUnregister,
  defaultValue,
  onAfterBlur,
  ...props
}: FormFieldTextProps<T, U>) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, shouldUnregister, defaultValue });

  const handleBlur: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      field.onBlur();
      onAfterBlur && onAfterBlur(e.target.value);
    },
    [field, onAfterBlur]
  );

  const { wrapProps, rest: inputProps } = extractWrapperProps(props);
  return (
    <FormFieldWrapper error={error} {...wrapProps}>
      <InputText
        data-testid={name}
        id={name}
        isRequired={wrapProps.isRequired}
        isInvalid={error !== undefined}
        {...inputProps}
        {...field}
        onBlur={handleBlur}
      />
    </FormFieldWrapper>
  );
};
