import { Link as ChakraLink } from '@chakra-ui/react';
import { forwardRef } from 'react';

import type { TextLinkProps } from './type';

import { HeadlessLink } from '@/components/ui';

export const TextLink = forwardRef<HTMLAnchorElement, TextLinkProps>(
  (
    {
      href = '#',
      color = 'blue.500',
      fontSize = 'md',
      isExternal,
      children,
      textDecoration,
    },
    ref
  ) => {
    return (
      <ChakraLink
        as={HeadlessLink}
        href={href}
        ref={ref}
        target={isExternal ? '_blank' : undefined}
        rel={isExternal ? 'norefferer' : undefined}
        color={color}
        fontSize={fontSize}
        isExternal={isExternal}
        textDecoration={textDecoration}
      >
        {children}
      </ChakraLink>
    );
  }
);
TextLink.displayName = 'TextLink';
