export const COMMON_LABEL = {
  email: 'メールアドレス',
  phoneNumber: '電話番号',
  address: '住所',
  zipcode: '郵便番号',
  prefecture: '都道府県',
  city: '市区町村以下',
  building: '建物名',
  password: 'パスワード',
};
